import React from "react";
import {Colors} from "../../ui-util/colors";
import ReportFormComponent from "./report-form.component";
import background from "../../image/background.svg";
import {Image} from "antd";
import logo from "../../image/lacoco-logo.svg";

const ReportPage = () => {
    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            overflow: 'hidden',
        }}>
            <div className="center" style={{marginTop: 30}}>
                <Image
                    width={100}
                    src={logo}
                />
            </div>
            <div style={{marginTop: 30}}>
                <h4 className="center"
                    style={{marginLeft: -150, color: Colors.sienna, fontWeight: 'bold', marginBottom: 20}}>Form
                    Report</h4>
                <div className="center">
                    <ReportFormComponent/>
                </div>
            </div>
        </div>
    )
}

export default ReportPage
