import React, {useEffect, useState} from 'react'
import {Button, Form, Image, Input, Spin, Upload, InputNumber} from "antd";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import {Colors} from "../../ui-util/colors";
import {useParams} from "react-router-dom";
import {Base64} from "js-base64";
import {createReportApi, uploadImageApi} from "../../api/report.api";

const {TextArea} = Input;

const ReportFormComponent = () => {
    let formRef = React.createRef()
    let {code} = useParams();
    const [expiredImage, setExpiredImage] = useState([])
    const [qrImage, setQrImage] = useState([])
    const [loadingExpiredImage, setLoadingExpiredImage] = useState(false)
    const [loadingQrImage, setLoadingQrImage] = useState(false)
    const [reportForm] = useState([
        {
            label: 'Kode Product',
            name: 'qr_serial',
            type: 'input'
        }, {
            label: 'Nama',
            name: 'name',
            type: 'input',
            required: true,
            messageAlert: 'Please input name!',
            placeholder: 'Cth: Agnes Monica'
        }, {
            label: 'Email',
            name: 'email',
            type: 'email',
            rule: {
                type: 'email',
                message: 'E-mail tidak valid!',
            },
            required: true,
            messageAlert: 'Please input email!',
            placeholder: 'Cth: agnesmonica@gmail.com'
        }, {
            label: 'No. Handphone',
            name: 'phone',
            type: 'number',
            required: true,
            messageAlert: 'Please input phone!',
            placeholder: 'Cth: 081243215678'
        }, {
            label: 'Deskripsi',
            name: 'description',
            type: 'textarea',
            required: true,
            messageAlert: 'Please input description!',
            placeholder: 'Cth: saya beli produk ini di shopee, toko xxxxx.xyz'
        }
    ])

    useEffect(() => {
        const qrCode = Base64.decode(code)
        formRef.current.setFieldsValue({
            qr_serial: qrCode
        })
    }, [])

    const onFinish = async (values) => {
        try {
            values.description = !!values.description ? values.description : ""
            values.expiredImage = expiredImage[1].url
            values.qrImage = qrImage[1].url
            await createReportApi(values)
            alert('Report berhasil di submit!')
            formRef.current.setFieldsValue({
                name: '',
                email: '',
                phone: '',
                description: ''
            })
            setExpiredImage([])
            setQrImage([])
            window.location.href = '/thankyou';
        } catch (e) {
            console.log('Error createReportApi', e)
        }
    }

    const uploadImage = (state) => {
        let data = state === 'expiredDate' ? expiredImage : qrImage
        const form = new FormData();
        form.append('file', data[0])
        uploadImageApi(form)
            .then(response => {
                let newData = data.concat([{url: response.data}])
                setData(state, newData)
                setLoading(state)
            })
            .catch(err => {
                setLoading(state)
                console.log('Err uploadImageApi', err.response)
            })
    }

    const setData = (state, data) => {
        if (state === 'expiredDate') {
            setExpiredImage(data)
        } else {
            setQrImage(data)
        }
    }

    const setLoading = (state, value) => {
        if (state === 'expiredDate') {
            setLoadingExpiredImage(value)
        } else {
            setLoadingQrImage(value)
        }
    }

    const beforeUploadImage = (e, state) => {
        setData(state, [e])
        setLoading(state, true)
    }

    return (
        <Form ref={formRef} name="control-hooks" onFinish={(values) => onFinish(values)} layout="vertical">
            {
                reportForm.map((item, index) => {
                    switch (item.type) {
                        case 'textarea':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={{color: Colors.sienna}}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}
                                >
                                    <TextArea placeholder={item.placeholder} style={{borderRadius: 7, width: 250}}/>
                                </Form.Item>
                            )
                        case 'email':
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={{color: Colors.sienna}}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        type: 'email',
                                        message: 'E-mail tidak valid!',
                                    }]}
                                >
                                    <Input disabled={item.name === 'qr_serial'} placeholder={item.placeholder}
                                           style={{borderRadius: 5, width: 250}}/>
                                </Form.Item>
                            )
                        case 'number' :
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={{color: Colors.sienna}}>{item.label}</div>}
                                    name={item.name}
                                    rules={[
                                        {required: item.required, min: 8, max: 14,pattern: `^(\\+62|62|0)8[1-9][0-9]{6,9}$`,message: 'No Handphone tidak valid'}
                                    ]}
                                >
                                    <Input type={'number'} placeholder={item.placeholder}
                                           style={{borderRadius: 5, width: 250}}/>
                                </Form.Item>
                            )
                        default:
                            return (
                                <Form.Item
                                    key={index.toString()}
                                    label={<div style={{color: Colors.sienna}}>{item.label}</div>}
                                    name={item.name}
                                    rules={[{
                                        required: item.required,
                                        message: item.messageAlert,
                                    }]}

                                >
                                    <Input disabled={item.name === 'qr_serial'} placeholder={item.placeholder}
                                           style={{borderRadius: 5, width: 250}}/>
                                </Form.Item>
                            )
                    }
                })
            }
            <Form.Item
                label={<div style={{color: Colors.sienna}}>Upload Expired Date Image</div>}
                name={'expiredDateImage'}
                rules={[{
                    required: true,
                    message: 'Expired Date Image tidak valid!',
                }]}
            >
                <Upload
                    customRequest={() => uploadImage('expiredDate')}
                    beforeUpload={(e) => beforeUploadImage(e, 'expiredDate')}
                    fileList={expiredImage}
                    showUploadList={false}
                >
                    {
                        !!expiredImage[1] ?
                            <Image style={{width: 100, height: 100}} src={expiredImage[1].url}
                                   preview={false}/> :
                            loadingExpiredImage ?
                                <Spin style={{width: 100, marginTop: 100}}
                                      indicator={<LoadingOutlined
                                          style={{color: Colors.dimgray, fontSize: 30}}/>}/> :
                                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                    }
                </Upload>
            </Form.Item>
            <Form.Item
                label={<div style={{color: Colors.sienna}}>Upload QR Image</div>}
                name={'qrImage'}
                rules={[{
                    required: true,
                    message: 'QR Image tidak valid!',
                }]}
            >
                <Upload
                    customRequest={() => uploadImage('qrImage')}
                    beforeUpload={(e) => beforeUploadImage(e, 'qrImage')}
                    fileList={qrImage}
                    showUploadList={false}
                >
                    {
                        !!qrImage[1] ?
                            <Image style={{width: 100, height: 100}} src={qrImage[1].url}
                                   preview={false}/> :
                            loadingQrImage ?
                                <Spin style={{width: 100, marginTop: 100}}
                                      indicator={<LoadingOutlined
                                          style={{color: Colors.dimgray, fontSize: 30}}/>}/> :
                                <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                    }
                </Upload>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    span: 1,
                    offset: 4,
                }}
            >
                <Button
                    style={{
                        width: 150,
                        height: 50,
                        fontSize: 16,
                        color: Colors.sienna,
                        borderRadius: 10,
                        borderColor: Colors.sienna,
                        backgroundColor: Colors.snow
                    }}
                    htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ReportFormComponent
