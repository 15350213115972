import React from "react";
import {Colors} from "../../ui-util/colors";
import validLogo from "../../image/valid-logo.svg";
import warningLogo from "../../image/warning-logo.svg"
import {Image, Row} from "antd";

const ResultValidComponent = (props) => {
    const {productDetail, totalScan, qrCode, expiredAt} = props
    const {product_name, image} = productDetail

    return (
        <div>
            <div className="center" style={{marginTop: 30}}>
                <img src={image} alt="test" width={250}/>
            </div>
            <div className="center"
                 style={{
                     color: Colors.sienna,
                     fontSize: 20,
                     fontWeight: 'bold',
                     marginTop: 10
                 }}>{product_name}
            </div>
            <p className="center" style={{color: Colors.sienna, fontSize: 13}}>{qrCode}</p>
            {!!expiredAt && <p className="center" style={{color: Colors.sienna, fontSize: 13}}>Tahun Expired Date
                : {expiredAt}</p>}
            <Row className="center">
                <Image src={totalScan > 50 ? warningLogo : validLogo} width={35}/>
                <h1 className="center" style={{
                    color: Colors.sienna,
                    fontWeight: 'bold',
                    marginLeft: 20
                }}>{totalScan > 50 ? 'Valid ' : 'Original '}
                    Product</h1>
            </Row>
            <div className="center"
                 style={{color: Colors.dimgray, fontSize: 16, textAlign: 'center', margin: 15}}>
                Congratulations, the product you bought is a product original from Lacoco En Nature. Always be careful
                when buying to avoid fake productsÏ
            </div>
        </div>

    )
}

export default ResultValidComponent
