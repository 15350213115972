import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const validateQRApi = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/qr-code/check`,
            requestBody,
            {headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'}})
            .then(response => {
                resolve(response.data.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const validateQRV2Api = (requestBody) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v2/qr-code/check`,
            requestBody,
            {headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'}})
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    validateQRApi,
    validateQRV2Api
}
