import React, {useEffect, useState} from "react";
import ResultValidComponent from "./result-valid.component";
import {Colors} from "../../ui-util/colors";
import {Button, Image, Row, Spin} from "antd";
import {Link, useParams} from "react-router-dom";
import {Base64} from "js-base64";
import {validateQRV2Api} from "../../api/validator.api";
import ResultUnValidComponent from "./result-unvalid.component";
import {LoadingOutlined} from "@ant-design/icons";
import background from "../../image/background.svg";
import logo from "../../image/lacoco-logo.svg";
import instagram from "../../image/instagram.svg"
import {lacocoBrandCode} from "../../ui-util/general-variable";

const publicIp = require('public-ip');

const SectionResultV2 = () => {
    let {code} = useParams();
    const [qrCode, setQrCode] = useState('')
    const [isOriginal, setIsOriginal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [productDetail, setProductDetail] = useState({})
    const [totalScan, setTotalScan] = useState(1)
    const [expiredAt, setExpiredAt] = useState('')

    useEffect(() => {
        if(code.length > 5){
            const productCode = Base64.decode(code)
            setQrCode(productCode)
            validateQR(productCode)
        }
    }, [])

    const validateQR = async (productCode) => {
        setLoading(true)
        const ipAddress = await publicIp.v4()
        try {
            let requestBody = {
                qr_serial: productCode,
                ip_address: ipAddress,
                brand_code: lacocoBrandCode()
            }
            const result = await validateQRV2Api(requestBody)
            if(result.code === 200){
                setExpiredAt(result.data.expired_at)
                setIsOriginal(result.data.isOriginal)
                setTotalScan(result.data.totalScan)
                setProductDetail(result.data.product)
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log('Error validateQR', e)
            alert('Kesalahan jaringan!, gagal melakukan validasi QR. mohon lakukan beberapa saat lagi!')
        }
    }

    const antIcon = <LoadingOutlined style={{fontSize: 30, color: Colors.sienna}} spin/>

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            overflow: 'hidden',
        }}>
            <div className="center" style={{marginTop: 30}}>
                <Image
                    width={100}
                    src={logo}
                />
            </div>
            {
                loading ?
                    <div className="page-header">
                        <Spin indicator={antIcon}/>
                    </div>
                    :
                    <div>
                        {
                            isOriginal ? <ResultValidComponent expiredAt={expiredAt} productDetail={productDetail} totalScan={totalScan} qrCode={qrCode}/> :
                                <ResultUnValidComponent/>
                        }
                        <Row className="center" style={{marginTop: 10}}>
                            <div style={{marginRight: 30}}>More Information</div>
                            <a href={'https://www.instagram.com/lacoco.id/'} target="_blank" style={{textDecoration: 'none'}}>
                                <Row>
                                    <img src={instagram} width={25}/>
                                    <div style={{marginLeft: 5, color: Colors.dimgray}}>@lacoco.id</div>
                                </Row>
                            </a>
                        </Row>
                        <div className="center" style={{marginTop: 10}}>
                            <Button style={{
                                width: 200,
                                height: 50,
                                fontWeight: 'bold',
                                fontSize: 20,
                                color: Colors.sienna,
                                borderRadius: 10,
                                borderColor: Colors.sienna,
                                backgroundColor: Colors.fee
                            }}>
                                {
                                    isOriginal ?
                                        <Link to={`/thankyou`} target="_blank" style={{textDecoration: 'none'}}>
                                            OK
                                        </Link>
                                        :
                                        <Link to={`/report/${code}`} style={{textDecoration: 'none'}}>
                                            Report
                                        </Link>
                                }
                            </Button>
                        </div>
                    </div>
            }
        </div>

    )
}

export default SectionResultV2
