import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ReportPage from "./page/report";
import SectionResult from "./page/section-result";
import ThankYouPage from "./page/thankyou-page";
import './font/sansserifflf-cufonfonts/SansSerifBookFLF.otf'
import Lacoco from "./page/lacoco";
import Scanner from "./page/scanner";
import SectionResultV2 from "./page/section-result-v2";

//Section result untuk handle QR yang lama, API V1
//Section result untuk handle QR baru, API v2

ReactDOM.render(
        <BrowserRouter>
            <Switch>
                <Route path="/lacoco" component={Lacoco}/>
                <Route path="/camera" component={Scanner}/>
                <Route path="/validate/:code" component={SectionResult}/>
                <Route path="/validated/:code" component={SectionResultV2}/>
                <Route path="/report/:code" component={ReportPage} />
                <Route path="/thankyou" component={ThankYouPage} />
            </Switch>
        </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
