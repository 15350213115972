import React from "react";
import background from "../../image/background.svg";
import {Image} from "antd";
import logo from "../../image/lacoco-logo.svg";
import thankYou from "../../image/thankyou.png"
import {Colors} from "../../ui-util/colors";

const ThankYouPage = () => {
    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            overflow: 'hidden',
        }}>
            <div className="center" style={{marginTop: 40}}>
                <Image
                    width={100}
                    src={logo}
                />
            </div>
            <div className="center" style={{marginTop: 40}}>
                <Image src={thankYou} width={300}/>
            </div>
            <h1 className="center" style={{color: Colors.sienna, fontWeight: 'bold', marginTop: 20}}>Thank You</h1>
            <h3 className="center" style={{color: Colors.sienna, marginTop: 10}}>For validate your product</h3>
        </div>
    )
}

export default ThankYouPage
