import {BrowserMultiFormatReader} from '@zxing/library'
import React, {useEffect, useRef} from 'react'

const Scanner = () => {
    const videoRef = useRef(null)
    const codeReader = new BrowserMultiFormatReader()

    useEffect(() => {
        startScan()
    }, [])

    const startScan = () => {
        const constraints = {
            video: {
                facingMode: {
                    exact: 'environment'
                },
            }
        }

        codeReader.decodeFromConstraints(constraints, videoRef.current,
            (result) => {
                if (result) {
                    if (result.text.includes('https://') || result.text.includes('http://')) {
                        let resultSplit = result.text.split('/')
                        window.location.href = `/validated/${resultSplit[4]}`
                    } else {
                        window.location.href = `/validated/${result.text}`
                    }
                }
            }
        )
    }

    return (
        <div>
            <div style={{width: '100%', height: 700, display: 'flex', justifyContent: 'center'}}>
                <video ref={videoRef} style={{width: '100%'}}/>
            </div>
        </div>
    )
}

export default Scanner
