import React, {useState} from "react";
import logo from "../../image/lacoco-logo.svg"
import {Colors} from "../../ui-util/colors";
import background from "../../image/background.svg";
import {Image} from "antd";

const Lacoco = () => {
    const [productCode, setProductCode] = useState('')

    const validateProductCode = () => {
        if(productCode.length < 5 ){
            alert('Mohon masukkan hasil scan barcode dari produk kamu')
        } else {
            if (productCode.includes('https://') || productCode.includes('http://')) {
                let resultSplit = productCode.split('/')
                window.location.href = `/validated/${resultSplit[4]}`
            } else {
                window.location.href = `/validated/${productCode}`
            }
        }
    }

    const openScanner = () => {
        window.location.href = '/camera'
    }

    return (
        <div style={{
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="center" style={{marginTop: 30}}>
                <Image
                    width={100}
                    src={logo}
                />
            </div>
            <h1 style={{marginTop: 15, fontWeight: 'bold', color: Colors.dimgray}} className={"center"}>Product
                Validator</h1>
            <div style={{width: 400}}>
                <div style={{margin: 30, fontSize: 16}}>
                    <p style={{marginTop: 30}}>Lakukan validasi produk lacoco dengan menginputkan kode produk atau scan
                        QR code yang terdapat pada produk untuk memastikan keaslian dari produk kamu</p>
                    <div style={{marginTop: 50}}>
                        <input value={productCode}
                               onChange={(e) => setProductCode(e.target.value)}
                               placeholder={"Input Product Code"} type={'text'}
                               style={{
                                   outline: 'none',
                                   borderWidth: 1,
                                   width: '100%',
                                   borderColor: '#9BA4B5',
                                   height: 40,
                                   borderRadius: 4,
                                   paddingLeft: 10
                               }}/>
                        <p style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>Or</p>
                        <button
                            onClick={openScanner}
                            style={{
                                width: '100%',
                                borderWidth: 1,
                                borderRadius: 4,
                                backgroundColor: '#655DBB',
                                height: 40,
                                color: 'white',
                                fontWeight: 'bold'
                            }}>Scan QR Code
                        </button>
                    </div>
                    <button onClick={validateProductCode} style={productCode.length > 0 ? {
                        marginTop: 50,
                        height: 40,
                        width: '100%',
                        borderRadius: 4,
                        fontWeight: 'bold',
                        borderWidth: 1,
                        color: Colors.sienna,
                        borderColor: Colors.sienna,
                        backgroundColor: Colors.fee
                    } : {
                        marginTop: 50,
                        border: 'none',
                        height: 40,
                        width: '100%',
                        fontWeight: 'bold',
                        borderRadius: 4,
                        backgroundColor: '#9BA4B5'
                    }}>Validate Product Code
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Lacoco
