import axios from "axios";
import {cloudFunctionBaseUrl} from "../ui-util/general-variable";

const DEFAULT_API_HEADER = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
};

const API_HEADER_FORM_DATA_WITH_TOKEN = {
    'Accept': 'application/json',
    'content-type': 'multipart/form-data',
};

const createReportApi = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v1/report/create`,
            data,
            {headers: DEFAULT_API_HEADER})
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

const uploadImageApi = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `${cloudFunctionBaseUrl()}/v2/file/upload`,
            data,
            {headers: API_HEADER_FORM_DATA_WITH_TOKEN})
            .then(response => {
                resolve(response.data)
            }).catch(err => {
            reject(err)
        })
    })
}

export {
    createReportApi,
    uploadImageApi
}
