const cloudFunctionBaseUrl = () => {
    return process.env.REACT_APP_BASE_URL
}

const lacocoBrandCode = () => {
    return process.env.REACT_APP_LACOCO_BRAND_CODE
}

export {
    cloudFunctionBaseUrl,
    lacocoBrandCode
}
