import React from 'react'
import {Colors} from "../../ui-util/colors";
import invalidImage from "../../image/invalid-image.png";
import invalidLogo from "../../image/invalid-logo.svg"
import {Image, Row} from "antd";

const ResultUnValidComponent = () => {
    return (
        <div>
            <div className="center" style={{marginTop: 30}}>
                <img src={invalidImage} alt="test" width={300}/>
            </div>
            <div className="center"
                 style={{
                     color: Colors.sienna,
                     fontSize: 20,
                     fontWeight: 'bold'
                 }}>Unknown Product
            </div>
            <p className="center" style={{color: Colors.sienna, fontSize: 13}}>Code Unknown</p>
            <Row className="center">
                <Image src={invalidLogo} width={35}/>
                <h1 className="center"
                    style={{color: Colors.sienna, fontWeight: 'bold', marginLeft: 20, marginTop: 5}}>Something
                    Wrong</h1>
            </Row>
            <div className="center" style={{color: Colors.dimgray, fontSize: 16, textAlign: 'center', margin: 15}}>The QR Code is not valid, be careful if there is
                an irregularity in the packaging, it could be a counterfeit
            </div>
        </div>
    )
}

export default ResultUnValidComponent
